import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/scss/fonts.sass";
import "assets/scss/dark/lyra.sass";

// import Index from "views/Index";

const Index = React.lazy(() => import("./views/Index"));
const Discover = React.lazy(() => import("./views/Discover"));
const Profile = React.lazy(() => import("./views/Profile"));
const Author = React.lazy(() => import("./views/Author"));
const Article = React.lazy(() => import("./views/Article"));
const Error = React.lazy(() => import("./views/Error"));
const Search = React.lazy(() => import("./views/Search"));
const Category = React.lazy(() => import("./views/Category"));

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Route path="/search" exact render={(props) => <Search {...props} />} />
        <Route
          path="/category"
          exact
          render={(props) => <Category {...props} />}
        />
        <Route
          path="/article"
          exact
          render={(props) => <Article {...props} />}
        />
        <Route
          path="/profile"
          exact
          render={(props) => <Profile {...props} />}
        />
        <Route path="/author" exact render={(props) => <Author {...props} />} />
        <Route
          path="/discover"
          exact
          render={(props) => <Discover {...props} />}
        />

        {/* Error */}

        {/* 4XX */}
        <Route
          path="/400"
          exact
          render={(props) => <Error {...props} code="400" />}
        />
        <Route
          path="/401"
          exact
          render={(props) => <Error {...props} code="401" />}
        />
        <Route
          path="/402"
          exact
          render={(props) => <Error {...props} code="402" />}
        />
        <Route
          path="/403"
          exact
          render={(props) => <Error {...props} code="403" />}
        />
        <Route
          path="/404"
          exact
          render={(props) => <Error {...props} code="404" />}
        />
        <Route
          path="/405"
          exact
          render={(props) => <Error {...props} code="405" />}
        />
        <Route
          path="/408"
          exact
          render={(props) => <Error {...props} code="408" />}
        />
        <Route
          path="/429"
          exact
          render={(props) => <Error {...props} code="429" />}
        />
        <Route
          path="/451"
          exact
          render={(props) => <Error {...props} code="451" />}
        />

        {/* 5XX */}
        <Route
          path="/500"
          exact
          render={(props) => <Error {...props} code="500" />}
        />
        <Route
          path="/502"
          exact
          render={(props) => <Error {...props} code="502" />}
        />

        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
